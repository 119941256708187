import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { RetailSettings } from "@/interfaces/retailSettings";
import { store } from "@/internal";
import { CustomerTableMetadata } from "@/metadata/customer";
import { customerActionService } from "@/services/customer.action.service";
import { customerService } from "@/services/customer.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TableSuccessModalResponse
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./CustomersList.template.vue";
const namespace: string = "CustomerModule";

@Component({
  components: {
    ActionsSubheaderComponent,
    TableComponent
  },
  mixins: [Template]
})
@ResetService(customerService)
export default class CustomersListComponent extends Vue {
  @Getter("customers", { namespace })
  public customers!: Customer[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("filterCustomers", { namespace })
  public filterCustomers!: Callback;
  @Action("searchToCustomerList", { namespace })
  public searchCustomerAction!: Callback;
  @Action("configService", { namespace })
  public configService!: Callback;
  @Action("clearCustomerList", { namespace })
  public clearCustomerList!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;

  public rowActions: TableAction[] = [];
  public headers: TableHeader[] = CustomerTableMetadata;
  public delayTimer!: number;
  public createModifyCustomerPermission = false;
  public value: string = "";
  public paginate = customerService.paginationAction();
  public generalActions = customerActionService.getCustomersListAction();

  public onSearch(term: string) {
    this.searchCustomerAction(term);
  }

  public onClose(update: boolean) {
    if (update) {
      this.searchCustomerAction("");
    }
  }

  public filter(params: object) {
    this.filterCustomers(params);
  }

  public back() {
    this.$emit("onBack");
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      itemsPerPage: pagination.itemsPerPage,
      currentPage: pagination.currentPage
    });
  }

  public async checkInCustomer(arg: TableSuccessModalResponse) {
    this.$emit("onCustomerCheckin", arg);
  }

  protected created() {
    this.setPageNav({
      title: "batches.customers_search",
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      },
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onClose
      }
    });
    this.createModifyCustomerPermission = this.hasPermission(
      policyList.createModifyCustomers
    );
    this.rowActions = customerActionService.getCustomerRowActions(
      this.checkInCustomer,
      this.createModifyCustomerPermission
    );
    this.headers.forEach((el: TableHeader) => {
      if (el.fieldComponent === "TableLimitComponent") {
        el.options = {
          showRemaining:
            this.retailSettings.sales_limit_view === "REMAINING" ? true : false
        };
      }
    });
    this.clearCustomerList();
    this.configService("customers");
    this.searchCustomerAction("");
    store.dispatch("CustomerModule/savedForCheckin", false);
  }
}
